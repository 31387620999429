var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "eid_container"
  }, [_c('div', {
    staticClass: "eid_titleBox"
  }, [_c('p', [_vm._v("尊敬的客户您好！")]), _c('div', {
    staticClass: "eid_titleBox_des"
  }, [_c('span', [_vm._v(_vm._s(_vm.detailData.statusMsg))]), _c('div', {
    staticClass: "redMoreBox",
    on: {
      "click": _vm.changeMore
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.redMore))]), _c('img', {
    style: {
      'transform': _vm.isReadMore ? 'rotate(270deg)' : 'rotate(90deg)'
    },
    attrs: {
      "src": _vm.r_icon
    }
  })])]), _vm.isReadMore ? _c('div', {
    staticClass: "eid_titleBox_msg"
  }, [_vm._v(" 备注：如需更改开票时间，或是其他发票相关信息，请联系您的专属客服 "), _c('span', {
    staticStyle: {
      "color": "#00B1FF"
    }
  }, [_vm._v(_vm._s(_vm.detailData.salesman) + "  " + _vm._s(_vm.detailData.salesmanPhone ? _vm.detailData.salesmanPhone : '023-88609999'))])]) : _vm._e()]), _c('div', {
    staticClass: "eid_content"
  }, [_c('div', {
    staticClass: "eid_content_progress_box"
  }, [_c('p', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.detailData.invoiceType == 1 ? "纸质专票" : _vm.detailData.invoiceType == 2 ? "纸质普票" : "电子增值税普通发票"))]), _c('div', {
    staticClass: "progress_box"
  }, [_vm._m(0), _c('div', {
    staticClass: "progress_box_item"
  }, [_vm.detailData.invoiceStatus == 2 || _vm.detailData.invoiceStatus == 3 ? _c('div', {
    staticClass: "progress_box_item_icon"
  }, [_c('img', {
    attrs: {
      "src": "https://obs.pujian.com/frontend/app/user/ice_suc.png"
    }
  })]) : _vm._e(), _vm.detailData.invoiceStatus < 2 ? _c('div', {
    staticClass: "wait_box"
  }, [_c('div', {
    staticClass: "wait_box_rd"
  })]) : _vm._e(), _c('p', {
    style: {
      'color': _vm.detailData.invoiceStatus < 2 ? '#B3B3B3' : ''
    }
  }, [_vm._v("开票中")])]), _c('div', {
    staticClass: "progress_box_item"
  }, [_vm.detailData.invoiceStatus == 3 ? _c('div', {
    staticClass: "progress_box_item_icon"
  }, [_c('img', {
    attrs: {
      "src": "https://obs.pujian.com/frontend/app/user/ice_suc.png"
    }
  })]) : _vm._e(), _vm.detailData.invoiceStatus < 3 ? _c('div', {
    staticClass: "wait_box"
  }, [_c('div', {
    staticClass: "wait_box_rd"
  })]) : _vm._e(), _c('p', {
    style: {
      'color': _vm.detailData.invoiceStatus < 3 ? '#B3B3B3' : ''
    }
  }, [_vm._v("开票完成")])]), _c('p', {
    staticClass: "progress_line"
  })])]), _vm.detailData.invoiceStatus == 3 ? _c('div', {
    staticClass: "eid_info"
  }, [_c('div', {
    staticClass: "eid_info_item"
  }, [_c('span', {
    staticClass: "line_name"
  }, [_vm._v("购方名称")]), _c('span', {
    staticClass: "line_info"
  }, [_vm._v(_vm._s(_vm.detailData.userName))])]), _vm._m(1), _c('div', {
    staticClass: "eid_info_item"
  }, [_c('span', {
    staticClass: "line_name"
  }, [_vm._v("开票类型")]), _c('span', {
    staticClass: "line_info"
  }, [_vm._v(_vm._s(_vm.detailData.invoicingMethod == 1 ? "按单开票" : _vm.detailData.invoicingMethod == 2 ? "定期开票" : "人工申请"))])]), _c('div', {
    staticClass: "eid_info_item"
  }, [_c('span', {
    staticClass: "line_name"
  }, [_vm._v("开票金额")]), _c('span', {
    staticClass: "line_info"
  }, [_vm._v("￥" + _vm._s(_vm.detailData.invoiceAmount))])]), _c('div', {
    staticClass: "eid_info_item",
    staticStyle: {
      "margin-bottom": "0"
    }
  }, [_c('span', {
    staticClass: "line_name"
  }, [_vm._v("关联订单")]), _c('div', _vm._l(_vm.detailData.orderList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "line_orders",
      on: {
        "click": function click($event) {
          return _vm.toOrderDetail(item.orderNumber);
        }
      }
    }, [_c('span', [_vm._v(_vm._s(item.orderTime))]), _c('span', [_vm._v(_vm._s(item.orderNumber))])]);
  }), 0)]), _vm.detailData.invoiceStatus == 3 && _vm.detailData.invoiceType == 51 ? _c('div', {
    staticClass: "ice_thumbnail_box"
  }, [_c('div', {
    staticClass: "ice_thumbnail_box_img",
    on: {
      "click": function click($event) {
        _vm.isShowInvoice = true;
      }
    }
  }, [_vm.detailData.invoiceList ? _c('div', {
    staticClass: "ice_thumbnail_box_img_num"
  }, [_vm._v(" 共" + _vm._s(_vm.detailData.invoiceList.length) + "张发票 点击预览 ")]) : _vm._e()])]) : _vm._e()]) : _vm._e()]), _vm.detailData.invoiceStatus == 3 ? _c('div', {
    staticClass: "operation_box"
  }, [_c('div', {
    staticClass: "operation_box_btn",
    on: {
      "click": _vm.backInvoice
    }
  }, [_vm._v("返回发票中心")]), _vm.detailData.invoiceType == 51 ? _c('div', {
    staticClass: "operation_box_btn",
    on: {
      "click": _vm.invoiceCopy
    }
  }, [_vm._v("发票分享")]) : _vm._e(), _vm.detailData.invoiceType == 51 ? _c('div', {
    staticClass: "operation_box_btn",
    on: {
      "click": _vm.invoiceDownload
    }
  }, [_vm._v("发票下载")]) : _vm._e(), _vm.detailData.invoiceType == 51 ? _c('div', {
    staticClass: "operation_box_btn",
    on: {
      "click": _vm.openEmail
    }
  }, [_vm._v("发送邮箱")]) : _vm._e()]) : _vm._e(), _c('b-modal', {
    attrs: {
      "scroll": "keep",
      "has-modal-card": "",
      "trap-focus": "",
      "destroy-on-hide": false,
      "aria-role": "dialog",
      "aria-modal": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "message-dialog"
        }, [_c('h4', {
          staticClass: "groupTitle"
        }, [_c('span', [_vm._v("请填写接收发票的邮箱地址")])]), _c('div', {
          staticClass: "rulerContent"
        }, [_c('div', {
          staticClass: "i_box"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.customerMail,
            expression: "customerMail"
          }],
          attrs: {
            "placeholder": "输入邮箱地址"
          },
          domProps: {
            "value": _vm.customerMail
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.customerMail = $event.target.value;
            }
          }
        })])]), _c('div', {
          staticClass: "email_btns"
        }, [_c('div', {
          staticClass: "email_btns_item e_c",
          on: {
            "click": function click($event) {
              _vm.isShowEmail = false;
            }
          }
        }, [_vm._v(" 取消 ")]), _c('div', {
          staticClass: "email_btns_item e_s",
          on: {
            "click": _vm.subEmailShare
          }
        }, [_vm._v(" 发送到邮箱 ")])])])];
      }
    }]),
    model: {
      value: _vm.isShowEmail,
      callback: function callback($$v) {
        _vm.isShowEmail = $$v;
      },
      expression: "isShowEmail"
    }
  }), _c('b-modal', {
    attrs: {
      "scroll": "keep",
      "has-modal-card": "",
      "trap-focus": "",
      "destroy-on-hide": false,
      "aria-role": "dialog",
      "aria-modal": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "invoice-dialog"
        }, [_c('div', {
          staticClass: "urlNum"
        }, [_vm._v(" " + _vm._s(_vm.actUrl + 1) + "/" + _vm._s(_vm.detailData.invoiceList.length) + " ")]), _c('div', {
          staticClass: "rulerContent"
        }, [_c('b-carousel', {
          attrs: {
            "autoplay": false,
            "pause-info": false,
            "arrow": _vm.arrow,
            "repeat": _vm.arrowBoth,
            "arrow-hover": _vm.arrowHover,
            "indicator": false
          },
          on: {
            "change": _vm.changeUrl
          }
        }, _vm._l(_vm.detailData.invoiceList, function (carousel, index) {
          return _c('b-carousel-item', {
            key: index
          }, [_c('iframe', {
            attrs: {
              "id": "iframe",
              "src": carousel.invoicePath,
              "frameborder": "0"
            }
          })]);
        }), 1)], 1)])];
      }
    }]),
    model: {
      value: _vm.isShowInvoice,
      callback: function callback($$v) {
        _vm.isShowInvoice = $$v;
      },
      expression: "isShowInvoice"
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "progress_box_item"
  }, [_c('div', {
    staticClass: "progress_box_item_icon"
  }, [_c('img', {
    attrs: {
      "src": "https://obs.pujian.com/frontend/app/user/ice_suc.png"
    }
  })]), _c('p', [_vm._v("申请开票")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "eid_info_item"
  }, [_c('span', {
    staticClass: "line_name"
  }, [_vm._v("发票内容")]), _c('span', {
    staticClass: "line_info"
  }, [_vm._v("商品明细")])]);
}];
export { render, staticRenderFns };